@font-face {
	font-family: 'lbet-st';
	src: url('/fonts/lbet-nus-regular.woff2') format('woff2'),
		url('/fonts/lbet-nus-regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'lbet-st';
	src: url('/fonts/lbet-nus-bold.woff2') format('woff2'),
		url('/fonts/lbet-nus-bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'lbet-st';
	src: url('/fonts/lbet-nus-light.woff2') format('woff2'),
		url('/fonts/lbet-nus-light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'lbet-st';
	src: url('/fonts/lbet-nus-medium.woff2') format('woff2'),
		url('/fonts/lbet-nus-medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'lbet-st';
	src: url('/fonts/lbet-nus-black.woff2') format('woff2'),
		url('/fonts/lbet-nus-black.woff') format('woff');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'lbet-mt';
	src: url('/fonts/lbet-mt-regular.woff2') format('woff2'),
		url('/fonts/lbet-mt-regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'lbet-mt';
	src: url('/fonts/lbet-mt-bold.woff2') format('woff2'),
		url('/fonts/lbet-mt-bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'lbet-mt';
	src: url('/fonts/lbet-mt-light.woff2') format('woff2'),
		url('/fonts/lbet-mt-light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'lbet-mt';
	src: url('/fonts/lbet-mt-medium.woff2') format('woff2'),
		url('/fonts/lbet-mt-medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'lbet-mt';
	src: url('/fonts/lbet-mt-black.woff2') format('woff2'),
		url('/fonts/lbet-mt-black.woff') format('woff');
	font-weight: 900;
	font-style: normal;
}

// icons
//@import "https://i.icomoon.io/public/b726b0f0dd/Volt/style.css";
@import 'fonts/volt-icons/style.css';
