@import 'inc/mixin';

.body {
	-webkit-overflow-scrolling: touch;
	background-color: #202126;
	overflow: hidden;
	margin: 0;
	padding: 0;
	user-select: none;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
	font-family: lbet-st, sans-serif;
	line-height: initial;
}

.wrapper {
	$edgeWidth: 80px;
	$sidenavWidth: calc(100% - #{$edgeWidth});

	position: relative;
	height: var(--app-height);
	min-height: var(--app-height);
	overscroll-behavior-x: none;
	overscroll-behavior-y: none;
	overflow-y: scroll;
	overflow-x: hidden;

	.sideNav {
		position: fixed;
		left: 0;
		top: 0;
		width: $sidenavWidth;
		height: var(--app-height);
		min-height: var(--app-height);
		background-color: #32343a;
		color: #202126;
		box-sizing: border-box;
		z-index: 1;
		overflow-y: auto;

		.navCap {
			height: 70px;
			box-sizing: border-box;
			background-color: rgba(0, 0, 0, 0.1);
			@include flex-vc;
			justify-content: center;

			.profileWrapper {
				display: flex;
				width: 100%;

				.balance {
					display: flex;
					align-items: center;
					flex-grow: 1;
				}
			}

			.userProfile {
				$size: 24px;

				height: 70px;
				box-sizing: border-box;
				padding-left: 8px;
				@include flex-vc;
				flex-grow: 1;

				&[data-auth] {
					// display: none;
				}

				.avatar {
					display: block;
					width: $size;
					height: $size;
					background: url('../img/mob/volt-avatar.svg') no-repeat center;
					background-size: $size;
					border-radius: $size;
					flex-shrink: 0;
					margin-right: 5px;
				}

				.usernameRow {
					min-width: 0;
				}

				.username {
					width: 100%;
					color: #fff;
					font-size: 16px;
					font-weight: 600;
					line-height: 22px;
					flex: 1;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					padding-right: 10px;
					box-sizing: border-box;
				}

				.pin {
					width: 100%;
					font-size: 12px;

					.title {
						color: #b3b4b5;
						font-weight: 600;
					}

					.value {
						color: #fff;
					}
				}
			}

			.btnAuth {
				$btn-color: #43a047;
				@include caps;
				@include flex-vc;
				background-color: $btn-color;
				height: 40px;
				padding: 1px 20px 0;
				border-radius: 3px;
				cursor: pointer;
				transition: background-color 0.1s ease-in-out;
				color: #fff;
				font-weight: 400;

				&:hover {
					background-color: lighten($btn-color, 7%);
					transition: background-color 0.1s ease-in-out;
				}
			}
		}

		.mainNav {
			padding-left: 0;
			padding-top: 20px;

			li,
			a {
				color: #faf9fc;
				display: flex;
				height: 40px;
				align-items: center;
				font-size: 16px;
				font-weight: 400;
				padding-left: 27px;
				text-decoration: none;

				&[data-auth] {
					// display: none;
				}

				&[data-page='close'] {
					filter: brightness(0) invert(42%) sepia(25%) saturate(3021%) hue-rotate(327deg) brightness(85%) contrast(115%);
				}

				&.divider {
					height: 1px;
					background-color: #56575b;
					//margin: 20px 0 20px 35px;
					margin: 20px 0 20px 72px;

					&::before {
						content: none;
					}
				}

				&::before {
					$size: 28px;

					content: '';
					display: block;
					width: $size;
					height: $size;
					background-position: center;
					background-repeat: no-repeat;
					background-size: 100% 100%;
					margin-right: 17px;
				}

				&.active,
				&:not(.divider):active {
					background-color: rgba(0, 0, 0, 0.07);
					color: #0dd278;

					&::before {
						filter: brightness(0) invert(59%) sepia(16%) saturate(5460%) hue-rotate(110deg) brightness(103%) contrast(90%);
					}
				}

				&.promoHighlight,
				&:not(.divider):active {
					background-color: rgba(0, 0, 0, 0.03);
					color: #f2c841;

					&::before {
						filter: brightness(0) invert(95%) sepia(45%) saturate(6825%) hue-rotate(322deg) brightness(97%) contrast(95%);
					}
				}

				&[data-page='main']::before {
					background-image: url('../img/mob/icon-home.svg');
				}

				&[data-page='game']::before {
					background-image: url('../img/mob/icon-game.svg');
				}

				&[data-page='history']::before {
					background-image: url('../img/mob/icon-history.svg');
				}

				&[data-page='stat']::before {
					background-image: url('../img/mob/icon-stat.svg');
				}

				&[data-page='check']::before {
					background-image: url('../img/mob/icon-check.svg');
				}

				&[data-page='help']::before {
					background-image: url('../img/mob/icon-help.svg');
				}

				&[data-page='cashier']::before {
					background-image: url('../img/mob/icon-cashier-s.svg');
				}

				&[data-page='rules']::before {
					background-image: url('../img/mob/icon-rules.svg');
				}

				&[data-page='close']::before {
					background-image: url('../img/mob/icon-close.svg');
				}

				&[data-page='promo']::before {
					background-image: url('../img/mob/icon-gift.svg');
					background-size: 24px;
					background-position: right center;
				}
			}
		}

		.version {
			font-family: Consolas, monospace;
			color: #85868a;
			font-size: 12px;
			position: absolute;
			bottom: 25px;
			left: 0;
			background-color: #2d2f34;
			padding: 2px 5px 2px 25px;
		}
	}

	.content {
		height: var(--app-height);
		min-height: var(--app-height);
		transform: translate3d(0, 0, 0);
		overflow-y: hidden;
		overflow-x: hidden;
		box-shadow: 17px 0 10px 20px rgba(0, 0, 0, 0.7), -1px 0px 0px 0px #3e3e3e;
		position: relative;
		z-index: 2;
		background: #202126;
		display: flex;
		flex-direction: column;

		.inner {
			flex-grow: 1;
			overflow-x: hidden;
			overflow-y: scroll;
			position: relative;
			display: flex;
			flex-direction: column;
			overscroll-behavior-y: none;
			scroll-behavior: smooth;
			background: #191b20;

			.page {
				// TODO: stat styles
				.stats-chart-wrapper {
					padding: 0 10px;

					.chart-title {
						display: flex;
						//border-bottom: 1px solid #141518;
						padding-bottom: 8px;
						padding-top: 10px;
						padding-left: 5px;
						@include flex-vc;

						.title {
							color: gray;
							@include caps;
							@include flex-vc;
							flex-grow: 1;
							font-size: 14px;

							&::before {
								content: '';
								background: url('../img/mob/icon-stat.svg') no-repeat center;
								display: block;
								width: 12px;
								height: 12px;
								margin-right: 7px;
								opacity: 0.5;
							}
						}

						.buttons {
							display: flex;

							.reload-chart {
								background-color: #0d0d0d;
								color: gray;
								font-size: 12px;
								@include caps;
								@include flex-vc;
								font-weight: 500;
								border-radius: 3px;
								cursor: pointer;
								opacity: 0.7;
								padding: 6px 7px 5px;

								&::before {
									content: '';
									background: url('../img/mob/icon-stat-reload.svg') no-repeat center;
									display: block;
									width: 14px;
									height: 14px;
									opacity: 0.5;
									margin-right: 3px;
								}

								&:active {
									opacity: 1;
								}
							}
						}
					}

					.chart-wrapper {
						height: 250px;
						position: relative;
						background-color: #17181c;
						border-radius: 3px;

						#stats {
							height: 100%;

							circle {
								//stroke: red;
							}

							text {
								fill: gray;
							}
						}

						.spinner {
							position: absolute;
							left: 0;
							top: 0;
							bottom: 0;
							right: 0;
							@include flex-vc;
							display: none;
							justify-content: center;
							text-align: center;
							width: 100%;
							background-color: #17181c;
							border-radius: 3px;

							&.active {
								display: flex;
							}
						}
					}
				}

				.stat-tables {
					display: flex;
					flex-direction: column;
					margin-top: 20px;

					.tb-col {
						padding: 0 10px;
						flex-grow: 1;
						flex-basis: 50%;
						flex-shrink: 1;
						margin-bottom: 25px;
					}

					.stat-tb-wrapper {
						.tb-title {
							color: gray;
							@include caps;
							@include flex-vc;
							flex-grow: 1;
							font-size: 14px;
							margin-bottom: 10px;
							padding-left: 5px;

							&::before {
								content: '';
								background: url('../img/mob/icon-stat-table.svg') no-repeat center;
								display: block;
								width: 12px;
								height: 12px;
								margin-right: 7px;
								opacity: 0.5;
							}

							i {
								font-style: normal;
								@include caps;
							}

							span {
								@include caps;
								font-size: 11px;
								background-color: #1d1e23;
								display: inline-block;
								border-radius: 2px;
								border: 1px solid #17181c;
								margin-left: 7px;
								font-weight: 500;
								padding: 3px 4px 2px;
							}
						}

						.tb-wrapper {
							$borderStyle: 1px solid #151619;

							background-color: #1d1e23;
							border-radius: 3px;
							overflow: hidden;
							border: $borderStyle;
							min-height: 55px;
							position: relative;

							.spinner {
								position: absolute;
								top: 0;
								left: 0;
								bottom: 0;
								right: 0;
								background-color: #17181c;
								width: 100%;
								@include flex-vc;
								justify-content: center;
								text-align: center;
								border-radius: 3px;
								display: none;

								&.active {
									display: flex;
								}
							}

							table {
								width: 100%;
								color: #858fa5;
								font-size: 12px;
								font-weight: 400;
								border-spacing: 0;
								border-radius: 10px;
								border-collapse: collapse;
								line-height: 10px;

								thead {
									tr td {
										@include caps;
										font-weight: 600;
										border-bottom: $borderStyle !important;
										height: 30px;
										background-color: #191a20;
									}
								}

								tr {
									td {
										height: 22px;
										border-bottom: $borderStyle;
										border-left: $borderStyle;
										vertical-align: middle;
										padding: 1px 0 0 5%;
										font-family: 'Lucida Console', Monaco, monospace;

										&:first-child {
											border-left: 0;
										}
									}

									&:last-child {
										td {
											border-bottom: 0;
										}
									}
								}
							}
						}
					}
				}
			}
		}

		header {
			display: flex;
			align-items: center;
			height: 70px;
			border-bottom: 1px solid #1a1b1e;
			box-shadow: 0 1px 0 0 #25262c;
			padding: 0 15px;
			box-sizing: border-box;
			flex-grow: 0;
			flex-shrink: 0;

			.hamburger {
				display: block;
				width: 46px;
				height: 60px;
				background: url(../img/mob/menu-hamburger.svg) no-repeat right center;
				background-size: 32px;
				margin-left: -15px;
			}

			.logo {
				display: block;
				background: url('../img/mob/logo.svg') no-repeat center;
				width: 80px;
				height: 40px;
				background-size: 100%;
				margin-left: 10px;
				touch-action: none;
				-webkit-tap-highlight-color: transparent;
				font-size: 0;
			}


			.userPanel {
				@include flex-vc;
				flex-grow: 1;
				justify-content: flex-end;

				&.authorized {
					.balance {
						background: #2a2b30;
						border-radius: 3px;
						@include flex-vc;
						padding: 0 10px;
						height: 42px;
						flex-direction: column;
						justify-content: center;
						margin-left: 10px;

						.title {
							font-size: 0.8rem;
						}

						.balance-content {
							display: flex;
							flex-direction: row;
							justify-content: center;
							align-items: center;

							.money {
								color: #fff330;
								font-size: 1rem;
								font-weight: 600;
								position: relative;
								top: 1px;
							}

							.icon-gel {
								background: url('../img/mob/icon-gel-yellow.svg') no-repeat center;
								width: 10px;
								height: 10px;
								background-size: 100% 100%;
								margin-left: 3px;
								position: relative;
								top: -2px;

								&::before {
									content: '' !important;
								}
							}
						}
					}

					.btnCashier {
						$color: #33353d;
						background-color: $color;
						background-image: url('../img/mob/icon-cashier.svg');
						background-repeat: no-repeat;
						background-position: center;
						background-size: 18px auto;
						border-radius: 3px;
						width: 48px;
						height: 42px;
						margin-left: 10px;

						&:active {
							background-color: lighten($color, 2%);
						}
					}
				}

				&.login {
					.btnAuth {
						$btn-color: #43a047;
						@include caps;
						@include flex-vc;
						background-color: $btn-color;
						height: 40px;
						padding: 1px 20px 0;
						border-radius: 3px;
						cursor: pointer;
						transition: background-color 0.1s ease-in-out;
						color: #fff;
						font-weight: 400;

						&:hover {
							background-color: lighten($btn-color, 7%);
							transition: background-color 0.1s ease-in-out;
						}
					}
				}
			}
		}

		.jackpots {
			display: flex;
			padding: 5px 6px;
		}

		.chartOuter {
			$transition: height 0.2s ease, margin-bottom 0.2s ease;

			position: sticky !important;
			top: 0;
			background: $dk-base-bg;
			z-index: 2;
			height: 195px;
			margin-bottom: 0;
			transition: $transition;

			&.sticky {
				box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.8);
			}

			&.mini {
				height: 150px;
				margin-bottom: 45px;
				transition: $transition;

				.chartPanel {
					height: 130px !important;
					transition: $transition;
				}
			}

			.chartInner {
				padding: 10px;
				box-sizing: border-box;
				transform: translate3d(0, 0, 0);
				transition: box-shadow 0.2s ease-out;
			}

			.chartPanel {
				box-sizing: border-box;
				position: relative;
				background-color: #2a2b31;
				padding: 10px;
				overflow: hidden;
				border-radius: 5px;
				-webkit-mask-image: -webkit-radial-gradient(white, black);
				flex-shrink: 0;
				flex-grow: 0;
				-webkit-transform: translate3d(0, 0, 0);
				height: 175px;

				canvas {
					width: 100%;
					height: 100%;
				}

				:global {
					.crash-msg {
						background-color: #312a31;
						position: absolute;
						left: 0;
						top: 0;
						width: 100%;
						height: 100%;
						@include flex-vc;
						justify-content: center;
						text-align: center;
						color: #fe4f4f;
						font-size: 20px;
						flex-direction: column;

						.text {
							@include caps;
							font-weight: 600;
						}

						.value {
							font-size: 28px;
							font-weight: 900;
						}
					}

					.accepting-bets-msg {
						width: 100%;
						height: 100%;
						position: absolute;
						background-color: #2a2b31;
						left: 0;
						top: 0;
						display: flex;
						align-items: center;
						justify-content: center;
						flex-direction: column;
						visibility: hidden;

						.text {
							color: #ffc72a;
							font-size: 17px;
							@include caps;
						}

						.progress {
							width: calc(100% - 90px);
							border: 1px solid #ffc72a;
							margin-top: 25px;
							height: 10px;
							border-radius: 15px;
							overflow: hidden;

							.progress-bar {
								position: relative;
								width: 0;
								height: 10px;
								background-color: #ffc72a;
								transition-duration: 0s;
								transition-timing-function: linear;

								&.move {
									width: 100% !important;
								}
							}
						}
					}

					.popup-messages {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						overflow: hidden;

						.msg {
							display: flex;
							font-size: 14px;
							font-weight: 700;
							position: absolute;
							width: 100%;
							bottom: 0;
							padding: 12px;
							align-items: center;
							box-sizing: border-box;
							transform: translate3d(0, 100%, 0);
							opacity: 0;
							transition: all 0.1s ease-out;

							&::before {
								content: '';
								display: block;
								width: 20px;
								height: 20px;
								background-size: 20px;
								background-repeat: no-repeat;
								background-position: center;
								position: relative;
								flex-shrink: 0;
							}

							&.success {
								background: #02c66f;
								color: #00311b;

								&::before {
									background-image: url('../img/mob/icon-success.svg');
									margin-right: 10px;
									filter: brightness(0) invert(14%) sepia(12%) saturate(4532%) hue-rotate(114deg) brightness(96%) contrast(103%);
								}
							}

							&.error {
								background: #fe4f4f;
								color: #2f0101;

								&::before {
									background-image: url('../img/mob/icon-error.svg');
									background-size: 22px;
									margin-right: 10px;
									filter: brightness(0) invert(10%) sepia(54%) saturate(1170%) hue-rotate(324deg) brightness(94%) contrast(117%);
								}
							}

							&.success,
							&.error {
								transform: translate3d(0, 0, 0);
								opacity: 1;
								transition: transform 0.2s cubic-bezier(0.94, 0, 0.21, 0.99);
							}
						}
					}
				}
			}
		}

		.ticketsWrapper {
			-webkit-transform: translate3d(0, 0, 0);
			background: #202126;
			padding-bottom: 1px;
			padding-top: 10px;
		}

		.betFooter {
			//TODO: Change min height after push-to reload option
			$color: #191b20;

			display: flex;
			flex-direction: column;
			background-color: $color;
			flex-grow: 1;
			flex-shrink: 0;
			//min-height: 70px;
			//min-height: calc(100vh - 562px);
			min-height: calc(100vh - 800px);
			padding: 0;
			position: relative;

			.innerNotch {
				$size: 12px;

				display: block;
				width: 100%;
				height: 10px;
				background: #202126;
				border-bottom-left-radius: 10px;
				border-bottom-right-radius: 10px;
				box-shadow: 0 3px 2px -1px rgba(0, 0, 0, 0.3),
				inset 0px -1px 0px 0px rgba(255, 255, 255, 0.03);

				&::before {
					content: '';
					display: block;
					width: $size;
					height: $size;
					background-color: $color;

					position: absolute;
					left: 50%;
					top: 0;
					transform: translateX(-50%) translateY(4px) rotate(45deg);
					box-shadow: inset 2px 2px 2px -1px rgba(0, 0, 0, 0.3),
					-1px -1px 0px 0px rgba(255, 255, 255, 0.03);
				}
			}

			.buttonsWrapper {
				display: flex;
				padding: 15px;

				.addTicketBtn {
					flex-grow: 1;
					@include flex-vc;
					height: 45px;
					text-align: center;
					justify-content: center;
					background-color: rgba(96, 125, 139, 0.2);
					@include caps;
					color: #191b20;
					border-radius: 3px;
					cursor: pointer;
					font-weight: 600;
					font-size: 14px;
					margin-left: 15px;

					i {
						position: relative;
						top: -1px;
						margin-right: 5px;
					}

					&:first-child {
						margin-left: 0;
					}

					&.regularTicketBtn {
						//background: #d9b43b;
						color: #bac0d9;
					}

					&.freespinTicketBtn {
						background: #0c3140;
						color: #fff;
					}
				}
			}
		}

		.batchBetButtons {
			display: flex;
			bottom: 0;
			left: 0;
			height: 60px;
			padding: 5px;
			width: 100%;
			box-sizing: border-box;
			flex-shrink: 0;
			overscroll-behavior-y: none;
			background-color: #202126;
			z-index: 12;

			.batchBtn {
				background-color: #3c3e49;
				@include flex-vc;
				@include caps;
				color: #bdc2d7;
				flex-grow: 1;
				text-align: center;
				justify-content: center;
				border-radius: 3px;
				cursor: pointer;
				font-size: 15px;
				font-weight: 500;
				margin: 0 2px;
				width: 33.333%;
				word-spacing: 1000px;

				&:first-child {
					margin-left: 0;
				}

				&:last-child {
					margin-right: 0;
				}

				&.backHome {
					display: flex;
					text-align: left;
					padding-right: 10px;

					&::before {
						content: '';
						display: block;
						width: 30px;
						height: 100%;
						flex-shrink: 0;
						background: url('../img/mob/icon-btn-back.svg') no-repeat center;
						background-size: 20px auto;
						opacity: 0.5;
					}

					&:active {
						background-color: lighten(#3c3e49, 5%);
					}
				}

				&.betAll {
					$color: #f2c841;
					$text: #000;
					background-color: rgba($color, 0.2);

					&:active {
						background-color: $color;
						color: $text;
					}
				}

				&.cashoutAll {
					$color: #43a047;
					$text: #fff;
					background-color: rgba($color, 0.2);

					&:active {
						background-color: $color;
						color: $text;
					}
				}

				&.cancelAll {
					$color: #e53935;
					$text: #9ca1b6;
					background-color: rgba($color, 0.2);

					&:active {
						background-color: $color;
						color: #fff;
					}
				}
			}
		}

		&::after {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: #000;
			pointer-events: none;
			opacity: 0;
			transition: opacity 0.3s linear;
		}

		.guide {
			h5 {
				font-size: 18px;
				margin: 0;
			}
		}
	}

	&.open {
		.content {
			transform: translate3d(calc(100% - #{$edgeWidth}), 0, 0) !important;
			transition: transform 0.3s ease-in-out;
		}
	}

	&.closed {
		.content {
			transform: translate3d(0, 0, 0) !important;
			transition: transform 0.3s ease-in-out;
		}
	}

	// dim content
	&.openStarted,
	&.closeStarted {
		.content {
			&::after {
				opacity: 0.3;
				transition: opacity 0.3s linear;
			}
		}
	}

	&.open {
		.content {
			&::after {
				opacity: 0.45;
				transition: opacity 0.3s linear;
			}
		}
	}

	&.open,
	&.openStarted,
	&.closeStarted {
		.content {
			overflow: hidden;

			&::after {
				pointer-events: initial;
			}
		}
	}

	// pages
	$pages: 'main', 'history', 'stat', 'check', 'help', 'rules';
	@each $page in $pages {
		&[data-page='#{$page}'] {
			.sidenav .main-nav li[data-page='#{$page}'] {
				/* background-color: rgba(0,0,0,0.05);
				 color: #0DD278;
		 */
				&::before {
					//filter: brightness(0) invert(59%) sepia(16%) saturate(5460%) hue-rotate(110deg) brightness(103%) contrast(90%);
				}
			}

			.content .inner .page[data-val='#{$page}'] {
				display: block;
			}

			// show batch buttons
			@if ($page == 'main') {
				.content .batch-bet-buttons {
					.back-home {
						display: none;
					}

					.bet-all {
						display: flex;
					}
				}
			}
		}
	}
}

.toastMessage {
	@include toastMessageStyle;
}

.historyWrapper {
	background: #202126;
	position: relative;

	.title {
		color: #b0bcd9;
		@include caps;
		padding-left: 10px;
		padding-top: 15px;
		font-size: 18px;
		padding-bottom: 15px;
		border-bottom: 1px solid #292c33;
		border-top: 1px solid #27282d;
		box-shadow: 0 -2px 2px -1px rgba(0, 0, 0, 0.3);
	}
}

:global {
	.toast-err-btn {
		@include errBtnStyle;
	}

	.toast-warn-btn {
		@include warnBtnStyle;
	}

	.dual-session-window {
		@include dualSessionStyles;
	}
}
