@import 'scss/inc/mixin';

.tabItems {
	list-style: none;
	border-bottom: 1px solid $dk-tab-line-bottom;
	padding: 0 10px;
	position: relative;
	display: flex;
	width: auto;
	align-items: center;

	.tabItem {
		display: inline-flex;
		@include caps;
		color: $dk-text-tabs;
		font-size: 15px;
		height: 54px;
		align-items: center;
		padding: 0 15px;
		cursor: pointer;
		font-weight: 400;
		transition: color 0.2s ease-in-out;
		text-decoration: none;
		flex-shrink: 1;
		box-sizing: border-box;
		@include center;
		text-align: center;

		&.active {
			color: $dk-text-active-tabs;
			//border-bottom: 3px solid $dk-tab-active-indicator;
		}

		&:hover {
			color: $dk-text-active-tabs;
			transition: color 0.2s ease-in-out;
		}

		.tabText {
		}

		i {
			display: none;
		}
	}

	.tabActiveIndicator {
		position: absolute;
		bottom: 0;
		left: inherit;
		width: inherit;
		height: 3px;
		background-color: $dk-tab-active-indicator;
		transition: width 0.3s ease-in-out, left 0.3s ease-in-out;
	}

	:global {
		.promoButton {
			display: none;
		}
	}

	&.promo {
		padding-right: 120px;

		:global {
			.promoButton {
				display: flex;
			}
		}
	}
}

.tabContents {
	flex-grow: 1;
	min-height: 0;
	align-items: stretch;

	.tabContent {
		height: 100%;
		display: none;

		&.active {
			display: flex;
		}

		.tabInner {
			padding: 10px 0;
			width: 100%;
			// TODO: scroll styles for .tabInner
		}
	}
}

@media (max-width: 1600px) {
	.tabItems {
		.tabItem {
			width: 100%;

			.tabText {
				display: none;
			}
			i {
				display: block;
			}
		}
	}
}
