@import 'scss/inc/mixin';
@import 'scss/inc/fonts';
@import 'scss/inc/animations';

.jackpot {
	display: flex;
	flex-direction: column;
	align-items: center;
	border: 1px dashed rgba($dk-text-base-color, 20%);
	padding: 5px 10px;
	border-radius: 5px;
	margin: 0 8px;
	width: 160px;
	box-sizing: border-box;
	cursor: pointer;
	white-space: nowrap;

	&.animate {
		//animation: jackpotAnimation 1s ease infinite;
		animation-name: jackpotAnimation;
		animation-duration: 1.2s;
		animation-timing-function: ease;
		animation-iteration-count: infinite;
		//animation-delay: 2s;
		border-style: solid;
		//border-width: 2px;
	}

	&:hover {
		border-color: rgba($dk-text-base-color, 30%);
		.title {
			opacity: 1;
		}
	}

	span {
		color: $dk-text-base-color;
		font-size: 22px;
		font-family: Consolas, monospace;
		font-weight: 600;
	}
	i {
		font-size: 13px;
		margin-left: 4px;
		position: relative;
		top: -2px;
		color: $dk-text-base-color;
	}

	.jackpotRow {
	}
	.title {
		color: $dk-btn-yellow;
		font-size: 11px;
		font-weight: 600;
		opacity: 0.9;
		@include caps;
	}

	&.mobile {
		$margin: 3px;

		width: initial;
		flex-grow: 1;
		box-sizing: border-box;
		padding: 2px 0;
		margin: 0 $margin;

		span {
			font-size: 20px;
		}
		i {
			font-size: 9px;
			margin: 0 0 0 2px;
			top: -1px;
		}

		.title {
			font-size: 10px;
		}
	}
}
