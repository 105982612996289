@import './inc/mixin';

.betColCap {
	background: #1e1f24;
	height: 8px;
	position: relative;
	z-index: 11;
	width: calc(100% - 16px);
	margin-left: 8px;

	&.scrolled {
		box-shadow: 0 1px 0 0 rgba(22, 23, 26, 0.62),
			0 4px 4px -2px rgba(0, 0, 0, 0.8);
	}
}

.betHeader {
	display: flex;
	height: 70px;
	padding: 10px;
	width: 100%;
	box-sizing: border-box;
	border-bottom: 1px solid rgba(22, 23, 26, 0.62);
	box-shadow: 0 1px 0 0 rgb(37, 38, 44), 0 -1px 0 0 rgb(37, 38, 44),
		0 -2px 0 0 rgba(22, 23, 26, 0.62);
	flex-shrink: 0;
	margin-top: 2px;
	position: relative;
	z-index: 1;

	&.scrolled {
		box-shadow: 0 1px 0 0 rgb(37, 38, 44), 0 -1px 0 0 rgb(37, 38, 44),
			0 -2px 0 0 rgba(22, 23, 26, 0.62), 0 -8px 5px -4px rgba(0, 0, 0, 0.4);
	}
}

.btn {
	background-color: #3c3e49;
	@include flex-vc;
	@include caps;
	color: #bdc2d7;
	flex-grow: 1;
	text-align: center;
	justify-content: center;
	border-radius: 3px;
	cursor: pointer;
	font-size: 15px;
	font-weight: 500;
	margin: 0 5px;

	&.betAll {
		$color: #f2c841;
		$text: #000;
		background-color: rgba($color, 0.2);

		@include hover {
			background-color: $color;
			color: $text;
			text-shadow: 0 0 0.5px $text;
		}

		&.mobile {
			margin: 0 2px;
		}
	}

	&.cashoutAll {
		$color: #3fc66b;
		$text: #fff;
		background-color: rgba($color, 0.2);
		transition: all 0.1s ease;
		margin-right: 0;

		span {
			display: none;
		}

		@include hover {
			background-color: $color;
			color: $text;
			text-shadow: 0 0 0.5px $text;
		}

		&.active {
			background-color: $color;
			color: $text;
			text-shadow: 0 0 0.5px $text;

			flex-flow: column;
			word-spacing: initial;
			word-break: initial;
			white-space: nowrap;
			width: 270px;
			max-width: 270px;
			font-size: 13px;
			transition: all 0.2s ease;

			@include hover {
				background: lighten($color, 3%);
			}

			span {
				font-size: 22px;
				display: block;

				i {
					font-size: 16px;
					position: relative;
					top: -2px;
					margin-left: 2px;
				}
			}
		}

		&.mobile {
			margin: 0 0 0 2px;
		}
	}

	&.cancelAll {
		$text: #9ca1b6;
		$textShadow: 0 0 0.5px $text;
		$background: #373840;
		$backgroundActive: #e53935;
		$color: #848999;

		background: $background;
		color: $color;
		cursor: default;
		text-shadow: $textShadow;
		margin-left: 0;

		@include hover {
			background: $background;
			color: $color;
			text-shadow: $textShadow;
		}

		&.active {
			text-shadow: $textShadow;
			background-color: $backgroundActive;
			cursor: pointer;
			color: #fff;

			@include hover {
				background-color: lighten($backgroundActive, 5%);
				transition: all 0.2s ease-in-out;
				text-shadow: 0 0 0.5px #fff;
			}
		}

		&.mobile {
			margin-right: 2px;
		}
	}

	&.cancelAll,
	&.betAll,
	&.cashoutAll {
		word-break: break-word;
		word-spacing: 100px;
		height: 50px;
		max-width: 33.33%;
	}

	&.addTicket {
		background-color: $dk-btn-yellow;
		color: #000;
		text-align: left;
		padding-right: 8px;

		&:hover {
			background-color: lighten($dk-btn-yellow, 5%) !important;
		}
		&:active {
			background-color: darken($dk-btn-yellow, 5%) !important;
		}

		i {
			font-size: 16px;
			margin-right: 8px;
			margin-left: 8px;
		}

		&.iconBtn {
		}

		&.addVirtualTicket {
			$color: #0f7cf3;

			margin-left: 10px;
			background: $color;
			color: #021326;

			&:hover {
				background: lighten($color, 2%) !important;
			}
		}
	}
}

.betTickets {
	display: flex;
	flex: 1;
	min-height: 0;
	background-color: #1e1f24;
	align-items: stretch;
	padding: 0;

	&:global(.scrollVisible) {
		padding-right: 3px;
	}

	.betTicketsInner {
		overflow-y: auto;
		flex: 1;
		@include miniScroll;
	}

	.addTicketCustomBtn {
		$background: #1e1f24;
		$border: 2px dashed #484a57;
		$arrSize: 16px;
		$textColor: #818499;

		background-color: $background;
		border: $border;
		margin: 20px auto;
		width: calc(100% - 20px);
		border-radius: 10px;
		padding: 20px;
		position: relative;
		color: $textColor;
		text-align: center;
		cursor: pointer;

		i {
			font-size: 18px;
			position: relative;
			margin-top: -2px;
			margin-bottom: 10px;
			display: block;
			color: #fac900;
			opacity: 0.6;
		}

		div {
			@include caps;
			font-size: 17px;
		}

		&::before {
			//content: '';
			display: block;
			width: $arrSize;
			height: $arrSize;
			background: $background;
			position: absolute;
			top: -$arrSize * 0.5 - 2px;
			left: 50%;
			transform: translateX(-50%) rotate(45deg);
			border-left: $border;
			border-top: $border;
			border-radius: 4px;
		}

		&:hover {
			//$background: #25262C;
			background: lighten($background, 2%);
			color: lighten($textColor, 10%);

			i {
				opacity: 1;
			}

			&::before {
				background: lighten($background, 2%);
			}
		}
	}
}

.betFooter {
	@include flex-vc;
	box-sizing: border-box;
	width: 100%;
	padding: 10px;
	border-top: 1px solid rgba(22, 23, 26, 0.62);
	//box-shadow: inset 0 1px 0 0 rgb(37, 38, 44);
	flex-shrink: 0;
	position: relative;

	.btn {
		height: 52px;
		margin: 0;

		&:hover {
			$color: #3c3e49;
			transition: all 0.2s ease-in-out;
			background-color: lighten($color, 5%);
		}
	}

	.infoPanel {
		border: 1px solid hsl(240, 9%, 11%);
		height: 50px;
		width: 50%;
		border-radius: 3px;
		margin-right: 10px;
		display: flex;
		align-items: center;
		background-color: rgba(0, 0, 0, 0.1);

		&.wide {
			width: 90%;
		}

		.infoItem {
			color: #b6bed7;
			flex: 1;
			padding: 2px 10px 0;
			height: calc(100% - 2px);
			display: flex;
			align-items: center;
			flex-flow: column;
			justify-content: center;

			&:nth-child(1) {
				flex-grow: 3;
			}
			&:nth-child(2) {
				flex-grow: 4;
			}
			&:nth-child(3) {
				flex-grow: 5;
				background-color: rgba(0, 0, 0, 0.1);
			}

			.title {
				@include caps;
				font-size: 12px;
				font-weight: bold;
				text-align: center;
				white-space: nowrap;
			}
			.value {
				text-align: center;
				font-size: 22px;
				font-weight: bold;
			}

			.ticketIcon {
				font-size: 16px;
				position: relative;
				top: -1px;
				margin-right: 5px;
				color: $dk-text-base-color;
			}
			.iconCurrency {
				font-size: 16px;
				position: relative;
				top: -1px;
				margin-right: 5px;
				color: $dk-text-base-color;
			}
			.iconVolt {
				font-size: 16px;
				position: relative;
				top: -1px;
				margin-right: 5px;
				color: $dk-text-base-color;
			}
		}

		.possibleWinTip {
			width: 400px;
			padding: 10px;
			backdrop-filter: blur(30px);
			opacity: 1;
			border-radius: 10px;

			&::after {
				border-top-color: rgba(0, 0, 0, 0.55);
				bottom: -7px;
			}

			:global {
				.tipWrapper {
					display: flex;
					align-items: center;

					.icon-help {
						font-size: 28px;
						padding-right: 15px;
					}
					.tip-text {
						.tip-text-title {
							@include caps;
							font-size: 17px;
							margin-bottom: 5px;
						}
						.tip-text-body {
							font-size: 15px;
							line-height: 20px;
							color: #b6c0d9;
						}
					}
				}
			}
		}
	}
}

.panelsResizeHandle {
	$color: #424550;

	height: 10px;
	flex-shrink: 0;
	cursor: ns-resize;
	text-align: center;

	&::after {
		content: '\e905';
		font-family: volt, sans-serif;
		color: #4d4d4d;
		font-size: 30px;
		position: relative;
		top: -10px;
	}

	&:hover {
		background-color: $color;
		border-radius: 2px;

		&::after {
			color: #959595;
		}
	}
	&:active {
		background-color: #0b0b0d;
	}
}

.hidden {
	display: none;
}

.toastMessage {
	@include toastMessageStyle;
}

.tktCashoutLimit {
	cursor: pointer;
}
.toolTipStyle {
	width: 300px;
	backdrop-filter: blur(5px) !important;
	opacity: 1 !important;
	border-radius: 10px !important;
	background: #080a0a !important;

	&::after {
		border-top-color: rgba(0, 0, 0, 0.55);
		bottom: -7px;
	}

	:global {
		.tipWrapper {
			display: flex;
			align-items: center;
			padding: 5px 0;

			.icon-help,
			.icon-attention {
				font-size: 28px;
				padding-right: 15px;
			}
			.tip-text {
				.tip-text-title {
					@include caps;
					font-size: 15px;
					margin-bottom: 5px;
					text-align: left;
				}
				.tip-text-body {
					font-size: 13px;
					line-height: 20px;
					color: #b6c0d9;
					text-align: left;
				}
			}
		}
	}
}
