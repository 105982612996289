@import 'scss/inc/mixin';

.ticket {
	$background: #2a2b31;
	padding: 10px;
	background-color: $background;
	border-radius: 4px;
	min-width: 0;
	overflow: hidden;
	border: 1px solid transparent;
	box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	transition: border-color 0.3s, box-shadow 0.3s;
	position: relative;
	text-align: left;
	margin: 2px 10px 10px;

	&.locked {
		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 110px;
			background-color: transparent;
			z-index: 11;
		}

		.inputGroup {
			& > div:not(.toggleBtn),
			& > input {
				filter: grayscale(1);
				opacity: 0.7;
			}

			.label {
				opacity: 1 !important;
				background: #303138 !important;
				//background: red !important;
			}

			.toggleBtn {
				opacity: 0.7;
				user-select: none;
				filter: grayscale(0.7);

				&.autoBetBtn {
					opacity: 1;
					z-index: 12;
					filter: grayscale(0);
				}
			}
		}
		.shortcuts {
			opacity: 0.7;
			filter: grayscale(0.1);
		}
	}

	&.virtual {
		$color: #071d26;
		background: $color;

		.inputGroup {
			border: 1px solid lighten($color, 4%);

			.toggleBtn {
				&::after {
					background: lighten($color, 5%);
				}

				&:hover::after {
					background: lighten($color, 8%);
				}
			}

			.label {
				background: #071d26;
			}

			.incBtn,
			.decBtn {
				background: lighten($color, 5%);
			}
		}
		.shortcuts {
			.btn {
				background: lighten($color, 2.5%);

				&:hover {
					background: lighten($color, 4%);
				}

				&.x2,
				&.allin {
					background-color: lighten($color, 6%);

					&:hover {
						//background-color: lighten($color, 3%);
						background-color: #fcca60;
						color: #000;
					}
				}
			}
		}

		&.locked {
			.label {
				background: $color !important;
				filter: grayscale(0) !important;
			}
		}
	}

	&.mob {
		padding: 7px;

		.inputGroup {
			position: relative;

			.label {
				position: absolute;
				top: -7px;
				background: $background;
				padding: 0 4px;
				//border: 1px solid red;
				margin: 0 auto;
				left: 50%;
				transform: translateX(-50%);
				border-radius: 3px;
			}
			.input {
				text-align: left;
				height: 40px;
			}

			.incBtn,
			.decBtn {
				display: none;
			}

			&.focused {
				padding: 0 5px;
				flex-basis: 130%;

				:global {
					.icon-gel,
					.icon-volt {
						display: none;
					}
				}

				.incBtn,
				.decBtn {
					display: flex;

					&.incBtn {
						margin-left: 5px;
					}
				}
			}
		}

		.shortcuts {
			padding-top: 6px;

			.btn {
				margin: 0 2px;
				&:first-child {
					margin-left: 0;
				}
				&:last-child {
					margin-right: 0;
				}
			}
		}

		&.virtual {
			.inputGroup {
				.label {
					background: #071d26;
					filter: grayscale(0);
				}
			}
		}
	}
}

:global(.scrollVisible) {
	.ticket {
		margin-right: 5px;
	}
}

.inputs {
	@include flex-vc;

	.inputGroup {
		@include flex-vc;
		flex-grow: 1;
		border: 1px solid #515360;
		border-radius: 4px;
		padding: 0 10px;
		box-sizing: border-box;
		cursor: text;
		transition: background-color 0.2s ease-in-out;
		box-shadow: 0 0 4px 3px rgba(0, 0, 0, 0);
		position: relative;

		&:active,
		&:focus {
			outline: none;
		}

		&.focused {
			background-color: #fcfcfc;
			border: 1px solid #000000;
			box-shadow: 0 0 4px 3px rgba(0, 0, 0, 0.3);
			transition: all 0.2s ease-in-out;

			.label {
				color: #3f434b;
				transition: all 0.2s ease-in-out;
				background: #fff;
			}

			input {
				color: #343334;
				transition: color 0.2s ease-in-out;
			}

			.icon-gel,
			.icon-volt {
				filter: grayscale(1) brightness(0) invert(37%) sepia(13%)
					saturate(1245%) hue-rotate(171deg) brightness(94%) contrast(90%);
				transition: none;
			}
		}

		&.betGroup {
		}

		&.cashoutGroup {
			margin-left: 10px;

			&.autoCashoutOff {
				:global(.icon-volt) {
					color: $dk-outcome-low !important;
				}
			}
		}

		.label {
			position: absolute;
			top: -7px;
			background: #2a2b31;
			padding: 0 4px;
			margin: 0 0 0 30px;
			transform: translateX(-50%);
			border-radius: 3px;
			@include caps;
			font-size: 12px;
			color: #9fa7ba;
			transition: all 0.2s ease-in-out;
			font-weight: 600;
		}

		.input {
			border: 0;
			background: transparent;
			height: 48px;
			text-align: right;
			width: 100%;
			color: #9ea7b9;
			font-size: 20px;
			font-weight: 500;
			flex-grow: 1;
			transition: color 0.2s ease-in-out;
			user-select: text;

			&:focus {
				outline: none;
			}
		}

		:global(.icon-gel),
		:global(.icon-volt) {
			color: #f2c841;
			font-size: 13px;
			margin: 0 0 0 5px;
		}

		.toggleBtn {
			$color-off: #494c58;
			$color-on: #02c66f;
			width: 35px;
			height: 26px;
			margin-left: 10px;
			display: block;
			position: relative;
			z-index: 10;
			cursor: default;

			&::before {
				content: attr(data-tip);
				white-space: nowrap;
				position: absolute;
				right: 100%;
				top: 0;
				background-color: darken($color-off, 1%);
				height: 26px;
				@include caps;
				line-height: 27px;
				box-sizing: border-box;
				font-size: 12px;
				color: #fff;
				border-top-left-radius: 3px;
				border-bottom-left-radius: 3px;
				overflow: hidden;
				max-width: 0;
				border: 0;
				padding: 0;
				cursor: pointer;
				font-weight: 500;
				transition: max-width 0.15s ease-out, padding-left 0.15s ease-out,
					padding-right 0.15s ease-out;
			}

			&::after {
				content: 'OFF';
				background-color: $color-off;
				color: #aab0c5;
				@include caps;
				font-size: 14px;
				font-weight: 500;
				display: block;
				width: 35px;
				height: 26px;
				border-radius: 3px;
				line-height: 27px;
				text-align: center;
				cursor: pointer;
			}

			&.on {
				&::before {
					background-color: darken($color-on, 4%);
					box-shadow: inset -1px 0 0 0 rgba(0, 0, 0, 0.2);
					//color: darken($color-on, 25%);
					color: #fff;
					//@include smooth-subpixel(#000);
				}

				&:hover {
					&:after {
						background-color: lighten($color-on, 2%);
					}
				}

				&:after {
					background-color: $color-on;
					color: #fff;
					content: 'ON';
				}
			}

			&:hover {
				&::after {
					background-color: lighten($color-off, 3%);
				}
			}
		}

		.incBtn,
		.decBtn {
			display: flex;
			align-items: center;
			background: #494c58;
			width: 26px;
			height: 26px;
			flex-shrink: 0;
			text-align: center;
			justify-content: center;
			border-radius: 3px;
			margin-left: 10px;
			cursor: pointer;
			color: #a8adc2;
			font-size: 14px;

			&.incBtn {
				&:hover {
					background: #3fc66b;
					color: #fff;
				}
			}
			&.decBtn {
				margin-left: 0;
				margin-right: 5px;

				&:hover {
					background: #dc362f;
					color: #fff;
				}
			}
		}
	}
}

.errors {
	.errorMsg {
		display: flex;
		align-items: center;
		color: #fff;
		background: rgba(137, 55, 53, 0.2);
		margin-top: 10px;
		box-sizing: border-box;
		border-radius: 3px;
		border: 1px solid rgba(137, 55, 53, 0.65);
		padding: 5px 10px 4px;

		.icon {
			padding-right: 10px;
			color: #e53935;
			i {
				font-size: 16px;
			}
		}

		.text {
			font-size: 14px;
			font-weight: 400;
		}
	}
}

.shortcuts {
	padding-top: 10px;
	height: 34px;
	box-sizing: content-box;

	.betShortcutsGroup,
	.cashoutShortcutsGroup {
		@include flex-vc;
		width: 100%;
		position: relative;
	}

	.betShortcutsGroup {
		left: calc(-100% - 20px);
		transition: left 0.3s ease;

		&.active {
			left: 0;
		}
	}

	.cashoutShortcutsGroup {
		top: -35px;
		left: calc(100% + 20px);
		transition: left 0.3s ease;

		&.active {
			left: 0;
		}
	}

	.btn {
		$color: #33353d;
		flex-grow: 1;
		background-color: $color;
		color: #9ea7b9;
		font-size: 16px;
		font-weight: 500;
		height: 35px;
		@include flex-vc;
		text-align: center;
		justify-content: center;
		border-radius: 3px;
		margin: 0 5px;
		flex-basis: 0;
		cursor: pointer;

		&.betShortcut,
		&.cashoutShortcut {
			:global(.icon-volt),
			:global(.icon-gel) {
				color: #e6e6e6;
				font-size: 12px;
				margin-left: 3px;
				font-weight: normal;
			}

			:global(.icon-gel) {
				font-size: 11px;
			}

			&:hover {
				:global(.icon-gel),
				:global(.icon-volt) {
					color: $dk-text-yellow;
				}
			}
		}

		&.x2,
		&.allin {
			$color: #434650;
			background-color: $color;

			&:hover {
				//background-color: lighten($color, 3%);
				background-color: #fcca60;
				color: #000;
			}
		}

		&:first-child {
			margin-left: 0;
		}

		&:last-child {
			margin-right: 0;
		}

		&:hover {
			background-color: lighten($color, 3%);
		}
	}
}

.toolTip {
	padding: 15px 10px !important;
	border-radius: 10px !important;
	opacity: 1 !important;
	backdrop-filter: blur(20px) !important;
	font-size: 16px !important;

	&::after {
		border-top-color: rgba(0, 0, 0, 0.55) !important;
		bottom: -7px !important;
	}
}

.actionButtons {
	padding-top: 10px;
	@include flex-vc;
	height: 90px;
	box-sizing: border-box;
	align-items: stretch;

	&.mobile {
		height: 60px;
	}
}
