@font-face {
	font-family: 'volt';
	src: url('volt.eot?mgkwmb');
	src: url('volt.eot?mgkwmb#iefix') format('embedded-opentype'),
		url('volt.woff2?mgkwmb') format('woff2'),
		url('volt.ttf?mgkwmb') format('truetype'),
		url('volt.woff?mgkwmb') format('woff'),
		url('volt.svg?mgkwmb#volt') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'volt' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-decrease:before {
	content: '\e91a';
}
.icon-wifi:before {
	content: '\e900';
}
.icon-chart:before {
	content: '\e917';
}
.icon-list:before {
	content: '\e918';
}
.icon-check:before {
	content: '\e916';
}
.icon-attention:before {
	content: '\e915';
}
.icon-add:before {
	content: '\e914';
}
.icon-alert-triangle:before {
	content: '\e901';
}
.icon-user:before {
	content: '\e912';
}
.icon-account:before {
	content: '\e912';
}
.icon-avatar:before {
	content: '\e912';
}
.icon-plane:before {
	content: '\e911';
}
.icon-img:before {
	content: '\e910';
}
.icon-next:before {
	content: '\e90d';
}
.icon-prev:before {
	content: '\e90e';
}
.icon-first:before {
	content: '\e90f';
}
.icon-monocle:before {
	content: '\e90c';
}
.icon-close:before {
	content: '\e90b';
}
.icon-search:before {
	content: '\e90a';
}
.icon-abka:before {
	content: '\e908';
}
.icon-aben:before {
	content: '\e909';
}
.icon-hide-chat:before {
	content: '\e907';
}
.icon-chat-closed:before {
	content: '\e906';
}
.icon-dots:before {
	content: '\e905';
}
.icon-help:before {
	content: '\e904';
}
.icon-ticket:before {
	content: '\e903';
}
.icon-tkt-remove:before {
	content: '\e902';
}
.icon-gel:before {
	content: '\e913';
}
.icon-volt:before {
	content: '\e919';
}
