@import 'scss/inc/mixin';

.tableHeadOuter {
}

.tableBodyOuter {
	overflow-y: auto;
	@include miniScroll;
}

.playersTable {
	border-collapse: collapse;
	border-spacing: 0;
	@extend %table-style;

	&.tableHead {
		tr td {
			height: 55px;
		}
	}
	&.tableBody {
		tr {
			&.active {
				td {
					&.colPlayer {
						color: $dk-table-head-text-color;
					}

					&.colCashout {
						span {
							color: $dk-table-head-text-color;
						}
					}

					&.colBet {
						span {
							color: $dk-table-head-text-color;

							&::before {
								filter: grayscale(1);
								opacity: 0.7;
							}
						}
					}

					&.colWin {
						span {
							color: $dk-table-head-text-color;
							background-color: $dk-cashout-bg;

							&::before {
								filter: grayscale(1);
								opacity: 0.7;
							}
						}
					}
				}
			}
			&.self {
				td {
					background-color: #212f2c;

					&.colPlayer {
						color: $dk-text-base-color;
					}
					&.colWin span {
						background-color: $dk-text-yellow;
					}
				}
			}
		}
	}

	td {
		&.colPlayer {
			padding-left: 25px;
		}
		&.colCashout,
		&.colBet,
		&.colWin {
			width: 22%;
		}

		&.colPlayer {
		}
		&.colCashout {
			span {
				display: inline-flex;
				color: $dk-text-base-color;
				align-items: center;
				font-weight: 500;
			}
		}
		&.colBet {
			span {
				display: inline-flex;
				color: $dk-text-base-color;
				align-items: center;
				font-weight: 500;

				&::before {
					display: block;
					content: '';
					//background: url("../img/icon-gel-yellow.svg") no-repeat center;
					width: 10px;
					height: 12px;
					background-size: auto 100%;
					margin-right: 5px;
					margin-top: -1px;
				}
			}
		}
		&.colWin {
			text-align: right;
			padding-right: 25px;

			span {
				display: inline-flex;
				align-items: center;
				background-color: #cbd3dc;
				color: $dk-base-bg;
				font-weight: 500;
				height: 25px;
				padding: 0 10px;
				border-radius: 25px;

				i {
					color: inherit;
					text-shadow: 0 0 0 $dk-base-bg;
				}
			}
		}

		:global {
			.icon-currency {
				font-size: 11px;
				color: #999a9c;
				margin-right: 5px;
				position: relative;
				top: -1px;
			}
			.icon-volt {
				color: $dk-text-yellow;
				font-size: 11px;
				margin-right: 5px;
			}
		}
	}
}
