@import 'scss/inc/mixin';

.actionButton {
	flex-grow: 1;
	box-sizing: border-box;
	@include flex-vc;
	//height: 80px;
	//height: 100%;
	@include caps;
	justify-content: center;
	text-align: center;
	font-size: 22px;
	font-weight: 500;
	border-radius: 4px;
	cursor: pointer;

	&.mobile {
		&.placeBet {
			font-size: 17px;
		}
		&.cancelBet {
			font-size: 17px;
		}
		&.betAccepted,
		&.betLoader {
			padding: 10px 0;
			font-size: 14px;

			.btnLabel {
				color: #9ea7b9;
				font-size: 15px;
			}
		}

		&.betCashout {
			padding: 10px 0;
			justify-content: center;
			justify-items: center;
			align-content: center;

			.btnLabel {
				font-size: 14px;
			}
			.btnCashoutValue {
				font-size: 24px;
				font-family: monospace;
				font-weight: bold;
				margin-top: -5px;

				:global(.icon-currency) {
					font-size: 12px;
					margin-left: 4px;
					position: relative;
					top: -1px;
				}
			}
		}
	}

	.btnLabel {
		@include caps;
		font-size: 18px;
		font-weight: 400;
	}

	&.placeBet {
		$color: #f2c841;
		$fspColor: #0f7cf3;

		background-color: $color;
		color: #000;

		&:hover {
			background-color: lighten($color, 5%);
		}

		&.virtual {
			background: $fspColor;

			&:hover {
				background: lighten($fspColor, 3%);
			}
		}
	}

	&.cancelBet {
		$color: #e04f4f;
		background-color: $color;
		color: #fff;

		&:hover {
			background-color: lighten($color, 3%);
		}
	}

	&.betAccepted,
	&.betLoader {
		@include flex-vc;
		flex-direction: column;
		background: rgba(0, 0, 0, 0.25);
		//height: 80px;
		cursor: default;

		.btnLabel {
			color: #9ea7b9;
		}
	}

	&.betCashout {
		$color: #02c66f;
		background-color: $color;
		color: #fff;
		flex-direction: column;

		&:hover {
			background-color: lighten($color, 5%);
		}

		.btnCashoutValue {
			font-size: 32px;
			font-family: monospace;
			font-weight: bold;

			:global(.icon-currency) {
				font-size: 26px;
				margin-left: 4px;
				position: relative;
				top: -1px;
			}
		}
	}

	&.disabled {
		&,
		&:hover {
			background-color: rgba(37, 144, 99, 0.16);
			cursor: default;
		}
	}
}

.ticketRemoveButton {
	//background: #494C58;
	background: rgba(255, 255, 255, 0.1);
	//height: 80px;
	width: 45px;
	border-radius: 4px;
	margin-left: 10px;
	cursor: pointer;
	color: #aab0c5;
	font-size: 22px;
	@include flex-vc;
	text-align: center;
	justify-content: center;
	padding-right: 1px;

	&:hover,
	&:active {
		background: #e53935;
		color: #fff;
	}
}
