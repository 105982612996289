//dark theme
$dk-base-bg: #16171a;
$dk-panel-bg: #202126;
$dk-text-base-color: #2dd682;
$dk-text-tabs: #b4bed5;
$dk-text-active-tabs: #ffffff;
$dk-text-yellow: #fff330;
$dk-btn-yellow: #ffca2a;
$dk-btn-grey: #4e5163;
$dk-chart-panel-bg: #2a2b31;
$dk-tab-line-bottom: #2c2d34;
$dk-tab-active-indicator: #32fa93;
$dk-table-head-text-color: #858fa5;
$dk-log-table-bg: #25262c;
$dk-outcome-low: #fe4f4f;
$dk-outcome-high: #2dd682;
$dk-check-button-bg: #3d404a;
$dk-btn-green: #43a047;
$dk-cashout-bg: #303139;
$dk-textbox-bg: #51545e;
$dk-chat-player-color: #717b8d;
$dk-chat-text-color: #c3cee5;
$dk-chat-date-color: #828da4;
$dk-blue: #0f7cf3;

:root {
	--toastify-color-warning: #ffa000;
	--toastify-text-color-warning: #a62e0a;
	--toastify-font-family: lbet-st, sans-serif;
}

@mixin flex-vc {
	display: flex;
	align-items: center;
}
@mixin center {
	display: flex;
	align-items: center;
	justify-content: center;
}

@mixin caps {
	font-family: lbet-mt, sans-serif;
	text-transform: uppercase;
}

@mixin miniScroll {
	&::-webkit-scrollbar {
		width: 4px;
	}

	&::-webkit-scrollbar-track {
		box-shadow: inset 0 0 6px #101217;
	}

	&::-webkit-scrollbar-thumb {
		background-color: rgba(255, 255, 255, 0.4);
		outline: 0;
	}
}

@mixin styled-scrollbar($bgColor: #191a1d, $thumbColor: #484a57) {
	overflow: auto;
	scrollbar-width: thin;
	scrollbar-color: $thumbColor $bgColor;

	&::-webkit-scrollbar {
		width: 5px;
	}

	&::-webkit-scrollbar-track {
		background: $bgColor;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $thumbColor;
		//border: 1px solid $scrollbarBG;
	}
}

%table-style {
	width: 100%;
	color: $dk-table-head-text-color;
	font-size: 14px;
	font-weight: 400;

	&.tableHead,
	thead {
		tr {
			td {
				height: 40px;
			}
		}
	}
	&.tableBody,
	tbody {
		tr {
			td {
				height: 40px;
				background-color: $dk-log-table-bg;
				border-bottom: 1px solid $dk-panel-bg;
			}

			&:hover {
				td {
					background-color: lighten($dk-log-table-bg, 1%);
				}
			}
		}
	}
}

@mixin toastMessageStyle {
	line-height: 20px;
	font-size: 14px;

	code {
		background: rgba(0, 0, 0, 0.3);
		color: #fff;
		border-radius: 3px;
		padding: 1px 4px;
		margin-left: 4px;
	}

	i {
		font-size: 9px;
		position: relative;
		margin-left: 2px;
	}
}

@mixin errBtnStyle {
	background: rgba(255, 255, 255, 0.2);
	color: #fff;
	display: block;
	margin: 15px auto 5px;
	transform: translateX(-10px);
	@include caps;
	font-size: 14px;

	&:hover {
		color: #fff;
		background: rgba(#fff, 0.3);
	}
}
@mixin warnBtnStyle {
	background: rgba(255, 255, 255, 0.3);
	color: #a62e0a;
	display: block;
	margin: 15px auto 5px;
	transform: translateX(-10px);
	@include caps;
	font-size: 14px;
	font-weight: 500;

	&:hover {
		color: #a62e0a;
		background: rgba(#fff, 0.4);
	}
}

@mixin dualSessionStyles {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(0, 0, 0, 0.95);
	z-index: 9999;
	backdrop-filter: blur(10px);

	div {
		background: #0d0f12;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 350px;
		height: 180px;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		color: #fff;
		font-size: 20px;
		border-radius: 5px;
		box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.7);
		line-height: 36px;
	}
}

:global {
	.wifi-icon {
		font-size: 18px !important;
	}
}

@mixin hover {
	&:active {
		@content;
	}

	@media (hover: hover) and (pointer: fine) {
		&:hover {
			@content;
		}
	}
}
