@keyframes iconAnimation {
	100% {
		background-position-y: -4096px;
	}
}
.netIcon {
	width: 128px;
	height: 128px;
	background: url('/img/network-sprite.png') left 0;
	font-size: 0;
	animation: iconAnimation 1.25s steps(32) infinite;
}
