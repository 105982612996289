@keyframes loader-bounce {
	0%,
	80%,
	100% {
		transform: scale(0);
	}
	40% {
		transform: scale(1);
	}
}

.loader {
	width: 70px;
	text-align: center;
	padding-top: 0;

	& > div {
		width: 10px;
		height: 10px;
		background-color: #333;
		position: relative;
		top: -2px;
		border-radius: 100%;
		display: inline-block;
		animation: loader-bounce 1.4s infinite ease-in-out both;
	}

	.bounce1 {
		animation-delay: -0.32s;
		background-color: #f2c841;
	}

	.bounce2 {
		animation-delay: -0.16s;
		background-color: #e53935;
	}

	.bounce3 {
		background-color: #02c66f;
	}
}
