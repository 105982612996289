@keyframes sk-bouncedelay {
	0%,
	80%,
	100% {
		transform: scale(0);
	}
	40% {
		transform: scale(1);
	}
}

@keyframes shortcuts-appear {
	0% {
		box-shadow: inset 0 0 0 15px #2a2b31;
	}
	100% {
		box-shadow: inset 0 0 0 0 #2a2b31;
	}
}

//<editor-fold desc="gif loader">
@keyframes ldio-ea2177s810g-0 {
	0% {
		background: #f2c841;
		transform: translateZ(0) translate(64px, 41px);
		backface-visibility: hidden;
	}
	100% {
		background: #e53935;
		transform: translateZ(0) translate(48px, 62px);
		backface-visibility: hidden;
	}
}

.ldio-ea2177s810g div:nth-child(1) {
	animation: ldio-ea2177s810g-0 0.9090909090909091s infinite linear;
	position: absolute;
	width: 18px;
	height: 18px;
	border-radius: 50%;
}

@keyframes ldio-ea2177s810g-1 {
	0% {
		background: #e53935;
		transform: translateZ(0) translate(48px, 62px);
		backface-visibility: hidden;
	}
	100% {
		background: #06b468;
		transform: translateZ(0) translate(22px, 54px);
		backface-visibility: hidden;
	}
}

.ldio-ea2177s810g div:nth-child(2) {
	animation: ldio-ea2177s810g-1 0.9090909090909091s infinite linear;
	position: absolute;
	width: 18px;
	height: 18px;
	border-radius: 50%;
}

@keyframes ldio-ea2177s810g-2 {
	0% {
		background: #06b468;
		transform: translateZ(0) translate(22px, 54px);
		backface-visibility: hidden;
	}
	100% {
		background: #0f7cf3;
		transform: translateZ(0) translate(22px, 27px);
		backface-visibility: hidden;
	}
}

.ldio-ea2177s810g div:nth-child(3) {
	animation: ldio-ea2177s810g-2 0.9090909090909091s infinite linear;
	position: absolute;
	width: 18px;
	height: 18px;
	border-radius: 50%;
}

@keyframes ldio-ea2177s810g-3 {
	0% {
		background: #0f7cf3;
		transform: translateZ(0) translate(22px, 27px);
		backface-visibility: hidden;
	}
	100% {
		background: #f2c841;
		transform: translateZ(0) translate(48px, 19px);
		backface-visibility: hidden;
	}
}

.ldio-ea2177s810g div:nth-child(4) {
	animation: ldio-ea2177s810g-3 0.9090909090909091s infinite linear;
	position: absolute;
	width: 18px;
	height: 18px;
	border-radius: 50%;
}

@keyframes ldio-ea2177s810g-4 {
	0% {
		background: #f2c841;
		transform: translateZ(0) translate(48px, 19px);
		backface-visibility: hidden;
	}
	100% {
		background: #f2c841;
		transform: translateZ(0) translate(64px, 41px);
		backface-visibility: hidden;
	}
}

.ldio-ea2177s810g div:nth-child(5) {
	animation: ldio-ea2177s810g-4 0.9090909090909091s infinite linear;
	position: absolute;
	width: 18px;
	height: 18px;
	border-radius: 50%;
}

.loadingio-spinner-balls-4516k9ycmnm {
	width: 88px;
	height: 88px;
	display: inline-block;
	overflow: hidden;
	background: none;
}

.ldio-ea2177s810g {
	width: 100%;
	height: 100%;
	position: relative;
	transform: translateZ(0) scale(0.88);
	backface-visibility: hidden;
	transform-origin: 0 0; /* see note above */
}

.ldio-ea2177s810g div {
	box-sizing: content-box;
}
//</editor-fold>

@keyframes jackpotAnimation {
	$lightColor: #373840;

	0% {
		background: transparent;
	}
	6% {
		background: $lightColor;
		filter: brightness(1.5) contrast(1.2);
		box-shadow: 0 0 0px 1px rgba(#54e285, 0.5);
	}
	10% {
		background: darken($lightColor, 10%);
		filter: brightness(1.2) contrast(1.1);
	}
	14% {
		background: $lightColor;
	}
}
