.footer {
	$color: #5d6273;
	color: $color;
	background: #1e1f24;
	flex-shrink: 0;
	flex-basis: 30px;
	display: flex;
	align-items: center;
	padding: 0 15px;
	border-top: 1px solid #27272e;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	justify-content: space-between;
	font-size: 13px;
	font-family: Consolas, lbet-st, sans-serif;

	.copyright {
		a {
			margin-left: 5px;
			color: inherit;
			text-decoration: none;
			border-bottom: 1px dotted rgba($color, 0.4);

			&:hover {
				color: lighten($color, 15%);
				border-bottom: 1px solid rgba($color, 0.8);
			}
		}
	}

	.version {
		cursor: pointer;

		&:hover {
			color: lighten($color, 10%);
			border-bottom: 1px solid $color;
		}
	}
}
