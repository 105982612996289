@import 'inc/fonts';
@import 'inc/mixin';

* {
	margin: 0;
	padding: 0;
	user-select: none;
}

body {
	background-color: #131416;
	display: flex;
	flex-direction: column;
	height: 100vh;
	font-family: lbet-st, sans-serif;
}

.App {
	display: flex;
	flex-flow: column;
	height: 100vh;
}

.toast-err-btn {
	@include errBtnStyle;
}

.toast-warn-btn {
	@include warnBtnStyle;
}

.dual-session-window {
	@include dualSessionStyles;
}

.promoButton {
	$background: #f2c841;

	background: $background;
	color: #332b0d;
	display: flex;
	height: 30px;
	align-items: center;
	border-radius: 5px;
	justify-self: flex-end;
	position: absolute;
	right: 10px;
	font-weight: bold;
	font-size: 14px;
	padding: 0 10px 0 30px;
	cursor: pointer;
	text-decoration: none;

	&::before {
		content: '';
		background: url('../img/icon-gift.svg') no-repeat center;
		background-size: 100%;
		width: 18px;
		height: 18px;
		position: absolute;
		left: 7px;
		top: 5px;
		filter: invert(100%) invert(14%) sepia(7%) saturate(1194%)
			hue-rotate(331deg) brightness(101%) contrast(90%);

		animation: swing;
		animation-duration: 2s;
		animation-iteration-count: infinite;
	}

	&:hover {
		background: lighten($background, 2%);
		box-shadow: 0 0 0 1px $background;
		color: inherit;

		&::before {
			animation: bounceIn;
			animation-duration: 2s;
			animation-iteration-count: 1;
		}
	}
}
