@import 'scss/inc/mixin';

.modal {
	:global {
		.modal-content {
			background: #16171a;
			color: #fff;
		}
		.modal-header {
			border-color: hsl(0, 0%, 7%);
		}
		.modal-title {
			@include caps;
		}

		.modal-footer {
			border-color: hsl(240, 0%, 7%);
		}

		.modal-body {
			@include miniScroll;
		}
	}

	&.modalDraggable {
		overflow-y: hidden;

		:global {
			.modal-content {
				box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.4);
			}
		}
	}
}

.jackpotModal {
	$totalColor: #26c580;
	$winColor: #ffca2a;

	.titleUp,
	.titleDown {
		@include caps;
		text-align: center;
		color: #d4ecff;
	}

	.jackpotWinWrap {
		border: 2px dashed darken($winColor, 30%);
		border-radius: 5px;
		margin-top: 20px;
		padding-top: 15px;
	}
	.jackpotNum {
		text-align: center;
		i {
			font-size: 32px;
			position: relative;
			top: -2px;
			margin-left: 4px;
		}

		&.jackpotTotal {
			color: $totalColor;
		}
		&.jackpotWin {
			color: $winColor;
		}
	}
}

.changeLogModal {
	$color: #9c9ea6;

	article {
		color: $color;
		margin-bottom: 25px;

		.head {
			display: flex;
			justify-content: space-between;
			border-bottom: 1px solid #333333;
			padding-bottom: 3px;
			margin-bottom: 10px;

			.version {
				color: #f19726;
			}
			.date {
				color: darken($color, 10%);
				font-size: 16px;
			}
		}

		.body {
			font-size: 16px;

			ul {
				li {
					line-height: 24px;

					&:hover {
						color: lighten($color, 15%);
					}
				}
			}
		}
	}
}
