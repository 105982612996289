@keyframes bannerAnimation {
	100% {
		background-position-y: -104px;
	}
}

.promoBanner {
	font-size: 0;
	margin-left: 0;
	width: 115px;
	min-width: 115px;
	height: 52px;
	background-size: 100%;
	background-position: top center;
	background-repeat: no-repeat;

	&.animated {
		animation: bannerAnimation 2s steps(2) infinite;
	}

	&.mobile {
		margin-right: -10px;
		flex-shrink: 1;
		transform: scale3d(0.8, 0.8, 1);
	}


	&:hover {
		filter: saturate(1.5) brightness(1.1);
	}
}
