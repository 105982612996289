@import 'inc/mixin';

.table {
	@extend %table-style;
	border-collapse: collapse;
	border-spacing: 0;
	table-layout: fixed;
	width: 100%;

	col {
		&.edition {
			max-width: 90px;
			width: 90px;
		}

		&.outcome,
		&.cashout,
		&.bet,
		&.win {
			width: 17%;
		}

		&.check {
		}

		&.key,
		&.hash {
			width: 33%;
		}
	}

	thead {
		tr th {
			height: 40px;
			font-weight: normal;

			&.edition {
				padding-left: 25px;
				text-align: left;
			}

			&.outcome,
			&.cashout,
			&.bet,
			&.win {
				text-align: right;
			}
			&.key,
			&.hash {
				text-align: center;
			}
		}
	}

	tbody {
		tr {
			&.selected {
				td {
					$selectColor: #ffca2a;
					background: #2d2f38;

					&.edition {
						border-left: 1px solid $selectColor;
						box-shadow: inset 2px 0 0 0 $selectColor;
					}
				}
			}
			&.current {
				td {
					$selectColor: #32fa93;
					background: #2d2f38;

					&.edition {
						border-left: 1px solid $selectColor;
						box-shadow: inset 2px 0 0 0 $selectColor;
					}
				}
			}
		}

		tr td,
		tr th {
			&.edition {
				padding-left: 25px;
				text-align: left;
				box-sizing: border-box;
			}

			&.outcome {
				font-family: Consolas, lbet-st, sans-serif;
				color: $dk-outcome-low;
				text-shadow: 0 0 0;
				text-align: right;

				&.low {
					color: $dk-outcome-low;
				}

				&.high {
					color: $dk-outcome-high;
				}
			}

			&.cashout,
			&.bet,
			&.win {
				text-align: right;
			}

			&.check {
				text-align: right;
				padding-right: 20px;
			}

			&.bet,
			&.win {
				span {
					color: $dk-text-yellow;

					:global(.icon-currency) {
						font-size: 12px;
						margin-right: 5px;
					}

					&.freeVolt {
						color: #1e84f4;
						font-weight: 500;

						:global(.icon-currency) {
							font-size: 14px;
							margin-right: 3px;
							position: relative;
							top: 1px;
							text-shadow: 0 0 1px;
						}
					}
				}
			}

			&.key,
			&.hash {
				padding-right: 15px;
				text-align: center !important;

				&.key {
					padding-left: 15px;
				}

				input {
					background: #202126;
					border: none;
					width: 100%;
					text-overflow: ellipsis;
					overflow: hidden;
					box-sizing: border-box;
					cursor: pointer;
					font-family: Consolas, 'Courier New', sans-serif;
					color: #7f8a9e;
					padding: 5px 5px;
					border-radius: 3px;

					&::selection {
						background-color: #32fa92;
						color: #000;
					}

					&:active,
					&:focus {
						outline: none;
					}
				}
			}

			// multi-bet
			&.multiBet {
				background: #292b32 url('/img/multi-bet-icon.svg') no-repeat left
					25px bottom calc(50% - 20px);

				.multiBetTable {
					@extend %table-style;
					table-layout: fixed;
					border-collapse: collapse;
					width: 100%;

					&.currentEdition {
						thead {
							cursor: default;
						}
					}
					thead {
						cursor: pointer;

						th {
							background-color: #292a30;
							border-left: 0;
							border-right: 0;

							&.edition {
								position: relative;
							}

							&.bet,
							&.win {
								span {
									background: rgba(39, 40, 47, 0.56);
									padding: 5px 10px;
									border-radius: 3px;
									position: relative;
									right: -10px;
									z-index: 1;
									color: $dk-text-yellow;
									text-shadow: 0 0 0;

									i {
										font-size: 11px;
									}
								}
							}

							&.cashout {
								span {
									display: inline-flex;
									background: #2b2c35;
									width: 24px;
									height: 24px;
									border-radius: 3px;
									align-items: center;
									justify-content: center;

									&::before {
										content: '*';
										display: block;
										width: 8px;
										height: 8px;
										background-size: 100%;
										font-size: 16px;
										line-height: 13px;
									}
								}
							}
						}
					}

					&.expanded {
						thead th {
							background-color: #2f313b;

							&.edition::before {
								content: '';
								display: inline-block;
								width: 10px;
								height: 10px;
								background: url('/img/icon-arrow-down.svg') no-repeat
									center;
								background-size: 100%;
								position: absolute;
								left: 8px;
								top: calc(50% - 5px);
							}
						}
					}

					&.collapsed {
						thead th.edition::before {
							content: '';
							display: inline-block;
							width: 10px;
							height: 10px;
							background: url('/img/icon-arrow-right.svg') no-repeat
								center;
							background-size: 100%;
							position: absolute;
							left: 8px;
							top: calc(50% - 5px);
						}
					}

					tbody {
						.subTicketRow {
							td {
								height: 33px;
								background-color: #292b32;

								&:hover {
									td {
										background-color: lighten(#292b32, 0.6%);
									}
								}

								&:first-child {
									border-bottom: 0;
									background-color: transparent;
								}

								&.rowNum {
									span {
										@include flex-vc;
										justify-content: center;
										text-align: center;
										background-color: #31343f;
										color: #858fa5;
										width: 22px;
										height: 22px;
										font-size: 11px;
										border-radius: 2px;
										margin-left: 5px;
									}
								}
								&.cashout,
								&.bet,
								&.win {
									font-size: 13px;
								}

								&.bet,
								&.win {
									span {
										color: #c8d7f7;
										opacity: 0.8;
										i {
											font-size: 10px;
										}
									}
								}

								&.cashout {
									font-weight: 500;
								}
							}

							&:last-child {
								td {
									border-bottom: 0;
								}
							}
						}
					}
				}

				&:hover {
					thead th {
						background-color: #333540;
					}
				}
			}
		}
	}
}

.tbaBtn {
	display: inline-flex;
	background: #2b2c35;
	width: 24px;
	height: 24px;
	border-radius: 3px;
	align-items: center;
	justify-content: center;
	color: $dk-text-yellow;
}

.checkEdition {
	background-color: $dk-check-button-bg;
	display: inline-flex;
	height: 30px;
	align-items: center;
	padding: 0 15px;
	border-radius: 30px;
	cursor: pointer;
	transition: background-color 0.2s ease-in-out;
	font-size: 13px;

	.checkBtnText {
	}
	i {
		display: none;
	}

	&:hover {
		background-color: lighten($dk-check-button-bg, 2%);
		transition: background-color 0.2s ease-in-out;
	}
}

.tktLimitWarning {
	color: #ffbd27;
	position: relative;
	bottom: -1px;
	cursor: pointer;
	margin-right: 2px;
	font-size: 15px;
}

.mobile {
	.edition {
		padding-left: 10px !important;

		&::before {
			left: 1px !important;
			width: 7px !important;
			height: 7px !important;
		}
	}
	.check {
		padding-right: 5px !important;
	}

	.bet,
	.win {
		span {
			padding: 5px !important;
			font-size: 12px;
			right: -1px !important;
		}
	}

	.win {
		span {
		}
	}

	.key,
	.hash {
		padding-right: 5px !important;
		input {
			text-overflow: initial !important;
		}
	}
}

@media (max-width: 1800px) {
	.checkEdition {
		border-radius: 5px;
		padding: 0 10px;

		.checkBtnText {
			display: none;
		}

		i {
			display: block;
			position: relative;
			left: -1px;
		}
	}

	.edition {
		padding-left: 15px !important;

		&::before {
			left: 4px !important;
			width: 8px !important;
			height: 8px !important;
		}
	}
	.check {
		width: 50px;
		padding-right: 15px !important;
	}
}
