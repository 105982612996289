.preloader {
	width: 100vw;
	height: 100vh;
	position: absolute;
	z-index: 99;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #131416;
}
