@import 'scss/inc/mixin';

.wrapper {
	display: flex;
	flex-grow: 1;
	min-height: 0;
}

.panel {
	display: flex;
	box-sizing: border-box;
	min-height: 0;
}

.leftCol {
	padding: 10px 5px 10px 10px;
	flex-direction: column;
	flex-grow: 7;
	max-width: 40%;
	width: 40%;
}

.middleCol {
	flex-basis: 500px;
	background-color: #1e1f24;
	border-radius: 3px;
	margin: 10px 5px;
	flex-direction: column;
	min-height: 0;
	width: 30%;
}

.rightCol {
	flex-grow: 7;
	flex-direction: column;
	min-height: 0;
	padding: 10px 10px 10px 5px;
	width: 30%;
}

.chartPanel {
	background-color: $dk-chart-panel-bg;
	border-radius: 3px;
	height: 350px;
	width: 100%;
	flex-shrink: 0;
	box-sizing: border-box;
	padding: 10px;
	position: relative;
}

.tabsPanel {
	background-color: $dk-panel-bg;
	flex-grow: 1;
	margin-top: 10px;
	border-radius: 3px;
	display: flex;
	flex-direction: column;
	min-height: 0;
}

.playersPanel {
	background-color: $dk-panel-bg;
	width: 100%;
	border-radius: 3px;
	display: flex;
	flex-direction: column;
	//min-height: 40%;
	//max-height: calc(100% - 200px);
	height: 100%;
	overflow: hidden;

	&.expanded {
		max-height: initial !important;
		flex: 1;
	}
}

.chatPanel {
	background-color: $dk-chart-panel-bg;
	border-radius: 3px;
	flex: 1;
	//min-height: 415px;
}

.chatClosedPanel {
	$color: #79797d;

	background-color: #2a2b31;
	margin-top: 10px;
	border-radius: 3px;
	padding: 10px;
	@include flex-vc;
	text-align: center;
	justify-content: center;
	cursor: pointer;
	color: $color;
	text-shadow: 0 0 0 $color;
	position: relative;
	font-size: 14px;

	i {
		margin-right: 5px;
	}

	&:hover {
		color: lighten($color, 8%);
	}
}

@media (max-width: 1800px) {
	.leftCol {
		width: 35%;
	}
}
@media (max-width: 1500px) {
	.middleCol {
		flex-basis: 480px;
	}
}

@media (max-height: 880px) {
	.chartPanel {
		height: 250px;
	}
}
