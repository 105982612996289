@import 'scss/inc/mixin';


.header {
	display: flex;
	height: 65px;
	background-color: $dk-panel-bg;
	box-sizing: border-box;
	align-items: center;
	padding: 0 15px;
	flex-shrink: 0;
	min-width: 1300px;

	.logo {
		width: 95px;
		height: 47px;
		min-width: 95px;
		background: url('/img/logo.png') left 0;
		font-size: 0;
		animation: logoAnimation 2.2s steps(74);
		animation-iteration-count: 1;
		animation-delay: 200ms;

		&.global {
			background: url("/img/logo-global.svg") no-repeat center;
			animation: none;

			&:hover {
				animation: none;
			}
		}

		&:hover {
			animation: logoAnimation 2.2s steps(74) infinite;
		}
	}


	.jackpots {
		flex-grow: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-top: 4px;

		.jackpotsWrapper {
			width: 500px;
			display: flex;
		}
	}

	.userPanel {
		@include flex-vc;

		& > div {
			margin: 0 10px;

			&:last-child {
				margin-right: 0;
			}
		}

		.username {
			@include flex-vc;
			color: $dk-text-base-color;
			font-weight: 500;

			&::before {
				content: '';
				display: block;
				width: 26px;
				height: 26px;
				background: url('/img/icon-user.svg') no-repeat center;
				margin-right: 5px;
			}
		}

		.pin {
			@include flex-vc;
			color: $dk-text-base-color;
			font-weight: 500;

			&::before {
				content: '';
				display: block;
				width: 26px;
				height: 26px;
				background: url('/img/icon-pin.svg') no-repeat center;
				margin-right: 5px;
			}
		}

		.btnAuth {
			$btn-color: #43a047;
			@include caps;
			@include flex-vc;
			background-color: $btn-color;
			height: 40px;
			padding: 1px 20px 0;
			border-radius: 3px;
			cursor: pointer;
			transition: background-color 0.1s ease-in-out;
			color: #fff;
			font-weight: 400;

			&:hover {
				background-color: lighten($btn-color, 7%);
				transition: background-color 0.1s ease-in-out;
			}
		}

		.btnCashier {
			@include caps;
			@include flex-vc;
			background-color: $dk-btn-yellow;
			font-weight: 600;
			height: 40px;
			padding: 1px 20px 0;
			border-radius: 3px;
			cursor: pointer;
			transition: background-color 0.1s ease-in-out;

			&:hover {
				background-color: lighten($dk-btn-yellow, 7%);
				transition: background-color 0.1s ease-in-out;
			}
		}

		.btnMenu {
			@include flex-vc;
			width: 40px;
			height: 40px;
			background-color: $dk-btn-grey;
			border-radius: 3px;
			cursor: pointer;
			justify-content: center;
			opacity: 0.75;
			transition: all 0.2s ease-in-out;
			color: #7e7f8b;

			&:hover {
				opacity: 1;
				transition: all 0.2s ease-in-out;
				background: #e3566b;
				color: #fff;
			}
		}
	}
}
.balance {
	$shadowSize: 8px;
	$animTime: 0.3s;
	$initBG: rgba(0, 0, 0, 0.07);
	$incBG: rgba(50, 240, 142, 0.2);
	$decBG: rgba(255, 79, 79, 0.2);
	$transition: background-color $animTime ease, box-shadow $animTime ease;

	@include flex-vc;
	font-size: 20px;
	color: $dk-text-yellow;
	background-color: $initBG;
	height: 50px;
	margin-right: 0;
	padding: 0 10px;
	border-radius: 3px;
	flex-flow: column;
	z-index: 2;
	min-width: 90px;
	line-height: 18px;
	cursor: pointer;

	.title {
		$color: #a3a9bd;

		@include caps;
		font-size: 14px;
		padding-top: 8px;
		color: $color;
		text-shadow: 0 0 0 $color;
	}
	.money {
		height: 23px;
		white-space: nowrap;

		i {
			font-size: 16px;
			margin-left: 5px;
			position: relative;
			top: -1px;
		}
	}

	// inc
	&:global(.bal-inc-enter) {
		background-color: $initBG;
		box-shadow: 0 0 0 -30px $incBG rgba($incBG, 0.01);
		transition: $transition;
	}
	&:global(.bal-inc-enter-active) {
		background-color: $incBG;
		box-shadow: 0 0 0 $shadowSize rgba($incBG, 0.08);
		transition: $transition;
	}

	// dec
	&:global(.bal-dec-enter) {
		background-color: $initBG;
		box-shadow: 0 0 0 $shadowSize rgba($decBG, 0.05);
		//transition: $transition;
	}
	&:global(.bal-dec-enter-active) {
		background-color: $decBG;
		box-shadow: 0 0 0 0 rgba($decBG, 0.1);
		transition: $transition;
	}

	&.mobile {
		font-size: 14px;
		padding: 0 5px;
		height: 45px;
		margin-right: -10px;
		min-width: 80px;

		.title {
			font-size: 12px;
			padding-top: 5px;
		}
		i {
			font-size: 10px;
		}

		& + & {
			margin-left: 8px;
		}
	}

	&.freevolt {
		background: #0a3566;
		color: #f2e82e;
		text-shadow: none;
		font-weight: 500;
		cursor: default;
		margin-left: 5px;

		.title {
			color: #afb6cc;
			text-shadow: none;
			font-weight: 500;
		}
	}
}
