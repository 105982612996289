@import 'scss/inc/mixin';
@import 'scss/tables.common.module';

.history {
	width: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	//border-bottom-left-radius: 5px;
	//border-bottom-right-radius: 5px;

	.tableHeadOuter {
	}
	.tableBodyOuter {
		overflow-y: auto;
		@include miniScroll;
	}

	&.mobile {
	}
}
