@import 'scss/inc/mixin';

.hidden {
	display: none !important;
}

.graphWrapper {
	width: 100%;
	height: 100%;

	canvas {
		width: 100%;
		height: 100%;
	}

	&.mobile {
		.acceptingMsg {
			padding: 0 20px;
			box-sizing: border-box;

			.progress {
				width: 100%;
			}
		}

		.crashMsg {
			font-size: 28px;
			.value {
				font-size: 64px;
			}
		}
	}
}

.netIconPreload {
	width: 0;
	height: 0;
	background: url('/img/network-sprite.png') no-repeat center;
}

.crashMsg {
	background-color: #312a31;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	border-radius: 3px;
	@include flex-vc;
	justify-content: center;
	text-align: center;
	color: #fe4f4f;
	font-size: 64px;
	flex-direction: column;

	.text {
		@include caps;
		font-weight: 500;
	}

	.value {
		font-size: 84px;
		font-weight: 900;
	}
}

.acceptingMsg {
	width: 100%;
	height: 100%;
	position: absolute;
	background-color: #2a2b31;
	left: 0;
	top: 0;
	border-radius: 3px;
	@include flex-vc;
	justify-content: center;
	flex-direction: column;
	//visibility: hidden;

	.text {
		color: #ff852a;
		font-size: 24px;
		@include caps;
	}

	.progress {
		width: 400px;
		border: 1px solid #ff852a;
		margin-top: 25px;
		height: 10px;
		border-radius: 15px;
		overflow: hidden;

		.progressBar {
			position: relative;
			width: 0;
			height: 10px;
			background-color: #ff852a;
			transition-duration: 0s;
			transition-timing-function: linear;

			&.move {
				width: 100% !important;
			}

			&:global(.progress-enter-active) {
				transition-duration: 0ms !important;
			}
			&:global(.progress-enter-done) {
				width: 100% !important;
			}
			&:global(.progress-exit-done) {
				width: 0 !important;
				transition-duration: 0ms !important;
			}
		}

		.hidden {
			display: none;
		}
	}
}
