@import 'scss/inc/mixin';

.guide {
	color: #fff;
	width: 100%;
	overflow-y: auto;
	@include styled-scrollbar;
	background: #25262c;
}

.imgFrame {
	border: 1px solid #2c2d34;
	display: table;
	margin: 15px auto;
	padding: 10px;
	border-radius: 5px;
}

.guideBlock {
	color: #b4bed5;
	line-height: 24px;
	padding: 30px 15px 15px;
	border-bottom: 1px solid #1b1b1e;

	h3 {
		@include caps;
		color: #ffbd27;
		margin-bottom: 25px;
		border-bottom: 2px solid #202126;
		padding-bottom: 15px;
	}

	h5 {
		@include caps;
		color: #ffbd27;
	}

	hr {
		background: #26262b;
		width: 70%;
		margin: 25px auto;
		//box-shadow: 0 -1px 0 0 rgba(255, 255, 255, .05);
		opacity: 1;
	}

	.highlight {
		font-weight: 600;
		position: relative;
		top: 1px;
		border-bottom: 1px dashed #ffbd27;
		padding-bottom: 2px;
	}

	li span {
		font-weight: 600;
	}

	code {
		background: #16171a;
		padding: 3px 5px;
		border-radius: 3px;
		color: #ffbd27;
	}
	center {
		margin-bottom: 10px;
		overflow-x: auto;
	}

	a {
		color: #02d075;
	}
}
